<template>
  <div class="AdminControls">
    <router-link
      :to="{ name: 'MemoransierLobby', params: { gameId } }"
      custom
      v-slot="{ route }">

      <div class="shareContainer">
        <qrcode-vue :value="host + route.fullPath" :size="180" level="H" />
        <div @click="copyLink(host + route.fullPath)" class="copyButton">
          <div v-if="linkCopied" class="tooltipCopied">{{ $t('copied') }} ✓</div>  
          {{ $t('copyLink') }}
        </div>
      </div>
    </router-link>

    <div v-if="players.length" class="startButtons">
      <div
        class="startButton"
        @click="startQuiz()">
        <p class="buttonTitle">{{ $t('startQuiz') }}</p>
      </div>
    </div>
  </div>
</template>

<translations>
  startQuiz: Start quiz
  startQuiz_no: Start reise-quiz
  copyLink: Copy & share link
  copyLink_no: Kopier & del link
</translations>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {QrcodeVue},

  data: function() {
    return {
      linkCopied: false,
    }
  },

  props: {
    gameId: {
      required: true,
    },
    players: {
      type: Array,
      default: () => [],
    }
  },

  mounted() {},

  methods: {
    copyLink(url) {
      if(!this.linkCopied){
        navigator.clipboard.writeText(url);
        this.linkCopied = true;
        setTimeout(() => {
          this.linkCopied = false;
        }, 2000);
      }
    },
     startQuiz() {
      this.$store.dispatch('moduleMemoransier/startGame', this.gameId);
      this.$emit('quiz-started');
    },
  },

  computed: {
     host () {
      return location.origin;
    },
  },

  filters: {},
}
</script>

<style lang="scss" scoped>
.AdminControls {
  .shareContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0.8em 0.3em;
    padding: 1em 1em;
    background: #21185dd0;
    border-radius: 0.3em;

    .copyButton{

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // background: #3A80D2;
      border: 1px solid rgba(255, 255, 255, 0.217);
      color: white;
      padding: 0.3em 0.8em;
      margin-top: 0.6em;
      border-radius: 0.3em;
      cursor: pointer;
      width: 100%;
      height: 2.5em;

      &:hover {
        // background: rgb(126, 137, 255);
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
      &:active {
        background: #3A80D2;
        border: 1px solid rgba(255, 255, 255, 0);
      }
      .tooltipCopied {
        // width: 120px;
        background-color: #333a92;
        color: #FFFFFF;
        text-align: center;
        font-size: 0.95em;
        border-radius: 0.5em;
        padding: 0.5em 1em;
        justify-self: center;
        align-self: center;
        margin-bottom: 7em;

        position: absolute;
        z-index: 1;
      }
    }
  }

  .startButtons {
    display: flex;
    justify-content: center;
  
    .startButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 1;
      background: #4452FE;
      border-radius: 0.3em;
      border: none;
      padding: 1em 2em;
      color: #ffffff;
      cursor: pointer;
      margin: 0em 0.3em;

      &:hover {
        background: #5e6bff;
      }

      @media (max-width: 620px) {
        width: 11em;
        padding: 1em;
      }

      .buttonWarmupTitle {
        margin: 0;
        padding-bottom: 0.2em;
        font-size: 0.9em;
        color: #A4B1CD;
      }
      .buttonTitle {
        margin: 0;
        font-size: 1em;
      }
    }
  }

}
</style>

