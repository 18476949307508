<template>
  <div class="Lobby">
    <div class="game-view-container">
      <div class="top-part">
        <h5>{{ $t('title') }}</h5>
        <h2>{{ $t('subtitle') }}</h2>
      </div>

      <div class="playersReadyProgress">
        <div class="avatars">
          <Avatar v-for="player of players" :key="player.userId" class="avatar" :avatar="player.avatar" />
          <div v-if="players.length === 6">...</div>
        </div>
        <p class="playersNumReady">{{ $tc('ready', players.length, { count: allPlayers.length }) }}</p>
      </div>

      <div v-if="isOwner && !gameStarted" class="linkAndButtonsContainer">
        <AdminControls :players="players" :game-id="gameId" @quiz-started="refresh()" />
      </div>
      <!-- <div>{{ game.options.fallingGame }}</div> -->
      <div v-if="!gameStarted && !isOwner" class="startButtonContainer">
        <div v-if="isFallingGame" class="startButton" @click="warmup()">
          {{ $t('warmup') }}
        </div>
        <p>{{ $t('whenQuizStarts') }}</p>
      </div>

      <div class="bottom-part">
        <img src="@/assets/images/mars.png" alt="mars" />
      </div>
    </div>
  </div>
</template>

<translations>
  title: Let's get ready to rumble!

  subtitle: Waiting for players to get ready
  subtitle_no: Venter på at spillere skal bli klare

  ready: '{count} players are ready | {count} player is ready to start | {count} players are ready to start'
  ready_no: '{count} spillere er klare | {count} spiller er klar for start | {count} spillere er klare for start'

  join: Join and play the game
  join_no: Bli med og spill

  copied: Copied
  copied_no: Kopiert

  warmup: Warm up while waiting
  warmup_no: Varm opp mens du venter
  whenQuizStarts: Quiz starts when everybody is present
  whenQuizStarts_no: Quizen starter når alle er her
 

</translations>

<script>
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar'
import AdminControls from './AdminControls'
import soundService from '@/services/sound-service'
import bgMusic from '../data/audio/starsMusic.mp3'

export default {
  components: { Avatar, AdminControls },
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      game: null,
      leaderboard: [],
      refreshId: null,
      sounds: [bgMusic],
    }
  },
  computed: {
    ...mapGetters({
      games: 'moduleMemoransier/games',
    }),

    allPlayers() {
      return this.leaderboard
    },
    players() {
      let allPlayers = this.allPlayers
      if (allPlayers.length > 6) {
        return allPlayers.slice(0, 6)
      } else {
        return allPlayers
      }
    },

    teamId() {
      return this.game && this.game.teamId
    },

    isFallingGame() {
      return this.game ? !this.game.options.fallingGame : false
    },

    numberOfGameQuestions() {
      return this.game.options.numberOfGameQuestions || 0
    },

    enableStartGame() {
      return this.game && !this.game.running && !this.game.startedAt && !this.game.leaderboardReveal
    },

    enableStopGame() {
      return this.game.running
    },

    hasJoined() {
      return !!this.players.find(p => p.userId === this.userId)
    },

    isOwner() {
      return this.game && this.game.owner === this.userId
    },

    userId() {
      return this.$store.getters['moduleAuth/profile'].id
    },

    gameStarted() {
      return this.game && this.game.running
    },
  },

  async mounted() {
    await this.refresh()

    if (this.isOwner) {
      soundService.play(bgMusic, { volume: 0.6, loop: true }, 'background')
    }
    if (!this.isOwner) {
      this.joinGame()
    }
    console.log(this.players, ' playersplayers')
  },

  beforeDestroy() {
    clearTimeout(this.refreshId)
    soundService.stop(bgMusic, 500)
  },

  methods: {
    endGame() {
      if (this.enableStopGame) {
        this.$store.dispatch('moduleMemoransier/endGame', this.gameId)
        this.$store.dispatch('moduleMemoransier/revealLeaderboard', this.gameId)
        this.refresh()
      }
      this.exit()
    },

    async refresh() {
      clearTimeout(this.refreshId)

      await this.$store.dispatch('moduleMemoransier/refresh')
      this.game = this.games.find(g => g.id === this.gameId)
      this.leaderboard = await this.$store.dispatch('moduleMemoransier/getLeaderboard', this.gameId)

      if (this.game) {
        if (this.game.leaderboardReveal) {
          this.gotoPodiumView()
          return
        }

        if (this.gameStarted && this.isOwner) {
          this.gotoPodiumView()
          return
        }

        if (this.gameStarted) {
          this.gotoQuiz()
          return
        }
      }
      this.refreshId = setTimeout(() => this.refresh(), 3000)
    },

    joinGame() {
      if (this.hasJoined) {
        console.log('already joined game')
        return
      }
      console.log('joining game')
      this.$store.dispatch('moduleMemoransier/joinGame', this.gameId)
      this.refresh()
    },

    gotoQuiz() {
      const options = this.game.options

      if (options.fallingGame) {
        this.$router.push({
          name: 'FallingGame',
          params: {
            gameId: this.gameId,
            courseId: options.courseId,
          },
        })
      } else {
        this.$router.push({
          name: 'MemoransierQuiz',
          params: {
            gameId: this.gameId,
            courseId: options.courseId,
          },
        })
      }
    },

    warmup() {
      this.$router.push({
        name: 'MemoransierWarmup',
        props: { gameId: this.gameId || this.$route.params.gameId },
      })
    },

    exit() {
      this.$router.push({
        name: 'DashboardHomeView',
      })
    },

    gotoPodiumView() {
      this.$router.replace({
        name: 'MemoransierPodium',
        props: {
          gameId: this.gameId,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.Lobby {
  background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
  display: flex;
  justify-content: center;
  .game-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: space-between;
    .top-part {
      margin-top: 5em;
      @media (max-width: 700px) {
        margin-top: 3em;
      }
      h5 {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        font-style: normal;
        text-align: center;
        color: #758ebf;
        margin-top: 0;
        margin-bottom: 20px;
        @media (max-width: 700px) {
          font-size: 12px;
        }

        @media (max-width: 400px) {
          font-size: 10px;
        }
      }

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        margin-top: 0;
        @media (max-width: 700px) {
          font-size: 18px;
        }

        @media (max-width: 400px) {
          font-size: 16px;
        }
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #758ebf;
      }
    }

    .bottom-part {
      display: flex;
      justify-content: center;
      img {
        width: 80%;
      }
    }

    .playersReadyProgress {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 1em;
      color: #ffffff;

      .playersNumReady {
        position: relative;
        margin: 0;
      }
      .avatars {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        width: 26em;
        height: auto;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 1em;
        margin-top: 0.5em;

        @media (max-width: 700px) {
          width: 90vw;
        }

        .avatar {
          border-radius: 8em;
          width: 2em;
          height: 2em;
          margin: 0.3em;
          // background: #0a1335;
        }
      }

      @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
      }

      @media (max-width: 400px) {
        font-size: 20px;
      }

      @media (max-width: 360px) {
        font-size: 18px;
      }
      .progress-bar-wrapper {
        background: #241a64;
        border-radius: 42px;
        @media (max-width: 700px) {
          width: 100%;
        }

        .progress-bar {
          height: 16px;
          background: #40ace0;
          border-radius: 42px;
          transition: 0.5s;
        }
      }
    }

    .shoutout {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #758ebf;
    }
  }

  .avatars {
    display: flex;
    flex-wrap: wrap;
    width: 20em;
    height: 100px;
    justify-content: center;

    .avatar {
      border-radius: 8em;
      width: 2em;
      height: 2em;
      margin: 0.3em;
      // background: #0a1335;
    }
  }

  .startButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: #758ebf;
      margin-top: 1.3em;
    }
    .startButton {
      display: flex;
      flex-direction: row;
      justify-content: center;

      background: #4452fe;
      border-radius: 0.3em;
      border: none;
      padding: 1.1em 4em;
      color: #ffffff;
      cursor: pointer;
      margin: 0em 0.3em;
      // width: 27em;

      &:hover {
        background: #5e6bff;
      }

      @media (max-width: 620px) {
        padding: 1em;
        width: 16em;
      }

      .buttonWarmupTitle {
        margin: 0;
        padding-bottom: 0.2em;
        font-size: 0.9em;
        color: #a4b1cd;
      }
      .buttonTitle {
        margin: 0;
        font-size: 1em;
      }
    }
  }
}
</style>
